import {
  setCenter,
  setZoom,
  setSelectedPlace,
  setSelectedAccommodationFilter,
  setDrawingMode,
  setLeftColMenu,
  setMapID,
  setCurrentLocation,
  setActiveStep,
} from "./mapReducers.js";

export const setCenterAction = (dispatch, center) => {
  dispatch(setCenter(center));
};

export const setZoomAction = (dispatch, zoom) => {
  dispatch(setZoom(zoom));
};

export const setSelectedPlaceAction = (dispatch, place) => {
  dispatch(setSelectedPlace(place));
};

export const setSelectedAccommodationFilterAction = (dispatch, filter) => {
  dispatch(setSelectedAccommodationFilter(filter));
};

export const setDrawingModeAction = (dispatch, mode) => {
  dispatch(setDrawingMode(mode));
};

export const setLeftColMenuAction = (dispatch, zoom) => {
  dispatch(setLeftColMenu(zoom));
};

export const setMapIDAction = (dispatch, mapID) => {
  dispatch(setMapID(mapID));
};
export const setCurrentLocationAction = (dispatch, location) => {
  dispatch(setCurrentLocation(location));
};

export const handleMapSearchAction = async (dispatch, payload) => {
};

export const setActiveStepAction = (dispatch, activeStep) => {
  dispatch(setActiveStep(activeStep));
};

