import {
  ArrowBackIos,
  KeyboardArrowDown,
  ArrowForwardIos,
  KeyboardArrowUp,
  Favorite,
  Close,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStepAction } from "../../store/Map/mapActions";
import mapIcon from "../../assets/icons/svg/map-icon.svg";
import { addToFavouriteList } from "../../store/User/userAction";
import { getCurrentUser } from "../../common/Helpers";

const LocationTag = ({ district }) => {
  return (
    <div className="flex justify-between items-center absolute top-6 w-full">
      <div className="flex items-center bg-[#1289F6] text-[#fff] pl-2 pr-4 py-2 rounded-full rounded-l-none w-[1/2]">
        <LocationOnIcon className="text-[#F9D100] m1-2" />
        <span className="!text-base !font-light">{district}</span>
      </div>
      <div className="mr-2">
        <img src={mapIcon} alt="map icon" />
      </div>
    </div>
  );
};
const ImageGallery = ({
  swipeList,
  balooDir,
  details,
  isMobileScreen,
  listingDetail,
  nearbyPlace,
}) => {
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const activeStep = useSelector((state) => state.map.activeStep);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // Array of 20 random placeholder images
  const images =
    details?.properties?.accommodationType === "rent" ||
    details?.properties?.accommodationType === "sell"
      ? details?.properties?.images
      : swipeList;
  const {
    id,
    price,
    thumbnail,
    district,
    city_in_trans,
    propertyName,
    review_score,
    review_nr,
    priceInfoContent,
    listingKey,
    badge,
  } = details?.properties || {};

  // Handle swipe gestures for vertical scrolling
  const handlers = useSwipeable({
    onSwipedUp: () => handleNextImage(), // Swipe up to go to the next image
    onSwipedDown: () => handlePreviousImage(), // Swipe down to go to the previous image
    preventScrollOnSwipe: true, // Prevent default scroll behavior
    trackMouse: true, // Enable mouse drag tracking
  });

  useEffect(() => {
    const index = swipeList.findIndex((item) => item.id === activeStep);
    setCurrentImageIndex(index);
  }, [activeStep]);

  useEffect(() => {
    if (balooDir === "") return;

    if (balooDir === "left") {
      if (currentImageIndex === 0) {
        setActiveStepAction(dispatch, swipeList[swipeList.length + 1]?.id);
      } else {
        const prevIndex = currentImageIndex - 1;
        setActiveStepAction(dispatch, swipeList[prevIndex]?.id);
      }
    }

    if (balooDir === "right") {
      handleFavourite();
      if (swipeList.length === currentImageIndex + 1) {
        setActiveStepAction(dispatch, swipeList[0]?.id);
      } else {
        const nextIndex = currentImageIndex + 1;
        setActiveStepAction(dispatch, swipeList[nextIndex]?.id);
      }
    }
  }, [balooDir]);

  const handleNextImage = () => {
    if (swipeList.length === currentImageIndex + 1) {
      setActiveStepAction(dispatch, swipeList[0]?.id);
    } else {
      const nextIndex = currentImageIndex + 1;
      setActiveStepAction(dispatch, swipeList[nextIndex]?.id);
    }
  };

  const handlePreviousImage = () => {
    if (currentImageIndex === 0) {
      setActiveStepAction(dispatch, swipeList[swipeList.length + 1]?.id);
    } else {
      const prevIndex = currentImageIndex - 1;
      setActiveStepAction(dispatch, swipeList[prevIndex]?.id);
    }
  };

  const handleFavourite = () => {
    dispatch(
      addToFavouriteList({
        userId: currentUser?.id,
        itemData: {
          listingId: id,
          name: propertyName,
          price,
          image: thumbnail,
          distance_cc: "",
          review_score: review_score,
          review_word: badge,
          total_review: review_nr,
          description: priceInfoContent,
          propertyType: listingKey,
          class: details?.properties?.class,
        },
      })
    );
  };

  return (
    <div className=" bg-gray-100 h-full">
      <div
        className="relative w-full max-w-lg overflow-hidden h-full"
        {...handlers}
      >
        <LocationTag
          district={nearbyPlace?.landmarks?.populars?.[0]?.landmark_name || ""}
        />
        {/* Image */}
        <img
          src={thumbnail}
          alt={`Random ${currentImageIndex}`}
          className="w-full"
          width={"100%"}
        />

        {/* Image count at the center of the image */}
        <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-white text-sm bg-black bg-[#362B2B80] px-3 py-1 rounded-full">
          {currentImageIndex + 1} / {images.length}
        </div>

        {/* Left Arrow */}
        <div
          onClick={handlePreviousImage}
          className="absolute left-1 top-1/3 cursor-pointer  bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
        >
          <ArrowBackIos className="!text-[24px]" />
        </div>
        <div
          onClick={handleNextImage}
          className="absolute right-1 top-1/3 cursor-pointer bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
        >
          <ArrowForwardIos className="!text-[24px]" />
        </div>
        {!isMobileScreen && (
          <React.Fragment>
            <div
              onClick={handleFavourite}
              className="absolute right-1 top-[46%] cursor-pointer"
            >
              <Favorite
                className="!text-[24px] rounded-4"
                sx={{ color: "#f00", background: "#fff" }}
              />
            </div>
            <div
              onClick={handleNextImage}
              className="absolute right-1 top-[54%] cursor-pointer"
            >
              <Close
                className="!text-[24px] rounded-4"
                sx={{ color: "#000", background: "#fff" }}
              />
            </div>
          </React.Fragment>
        )}
        {!isMobileScreen && (
          <React.Fragment>
            <div
              onClick={handlePreviousImage}
              className="absolute top-10 left-1/2 cursor-pointer  bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
            >
              <KeyboardArrowUp className="!text-[24px]" />
            </div>
            <div
              onClick={handleNextImage}
              className="absolute bottom-10 left-1/2  cursor-pointer bg-primary-dark/50 rounded-full w-10 h-10 flex justify-center items-center"
            >
              <KeyboardArrowDown className="!text-[24px]" />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ImageGallery;
