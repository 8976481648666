import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { Map, useMap, useMapsLibrary } from "@vis.gl/react-google-maps";
import MapSearch from "../MapSearch";
import AccommodationFilter from "../AccommodationFilter";
import { useSelector } from "react-redux";
import "./Map.css";
import { Button } from "@mui/material";
import BottomSheetTabs from "../../BottomSheetTabs";
import LMGeojson from "./LMGeojson";
import DrawPlaces from "./DrawPlaces";
import Tools from "./Tools";
import DistanceSlider from "../../DistanceSlider";
import DrawingOverlay from "./DrawingOverlay";
import { Polyline } from "./Polyline";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NearbyPlacesFetcher from "./NearbyPlaceFetcher";
import CurrentLocationMarker from "./CurrentLocationMarker";

const LMMap = () => {
  const center = useSelector((state) => state.map.center);
  const zoom = useSelector((state) => state.map.zoom);
  const mapID = useSelector((state) => state.map.mapID);

  const [isRadiusActive, setIsRadiusActive] = useState(false);
  const { hotels } = useSelector((state) => state.hotels);
  const filterState = useSelector((state) => state.filter);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const containerRef = useRef(null);

  const [drawing, setDrawing] = useState(false);
  const [path, setPath] = useState(null);
  const [places, setPlaces] = useState([]);
  const [selectBounds, setSelectBounds] = useState(null);
  const map = useMap();
  const markerLib = useMapsLibrary("marker"); // Load the marker library

  const handleApply = useCallback((paths) => {
    setDrawing(false);
    setPath(paths);
  }, []);

  const handleCancel = useCallback(() => {
    setDrawing(false);
  }, []);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const onRadiusCheckBoxChange = useCallback(
    (isChecked) => {
      if (!isChecked) {
        map.setCenter({
          lat: filterState?.latitude,
          lng: filterState?.longitude,
        });
        map.setZoom(10);
        setSelectBounds(null);
      }
      setIsRadiusActive(isChecked);
    },
    [filterState, map]
  );

  const removeBoundary = useCallback(() => {
    setPath(null);
    setSelectBounds(null);
  }, []);

  const handleBoundsChange = useCallback((bounds) => {
    setSelectBounds(bounds);
  }, []);

  const onPlacesFetched = useCallback((fetchedPlaces) => {
    setPlaces(fetchedPlaces);
  }, []);

  const memoizedPlaces = useMemo(() => places, [places]);

  const mapCore = useMapsLibrary("core");

  return (
    <div
      className="relative flex flex-col w-full h-full my-map rounded-10"
      ref={containerRef}
    >
      <AccommodationFilter />
      <div className="relative flex flex-col w-full h-full gap-6 my-map rounded-10">
        <div className="absolute w-full py-2 flex flex-col flex-1 gap-2 max-w-lg z-10">
          <MapSearch onPlaceChanged={() => console.log("Place changed")} />
        </div>

        <div
          variant="contained"
          className="z-10 bottom-8 left-3 absolute bg-[#fff] rounded-lg drop-shadow-2xl p-2 text-center cursor-pointer"
          onClick={handleOpen}
        >
          <FavoriteIcon
            className="text-white"
            sx={{ color: "#F24E1E" }}
            style={{ fontSize: "28px" }}
          />
          <div className="text-xs font-normal">Options</div>
        </div>

        {isRadiusActive && (
          <DistanceSlider
            onRadiusValue={handleBoundsChange}
            onPlacesUpdate={setSearchText}
          />
        )}

        {path && path.length && (
          <Button
            variant="contained"
            className="z-10 top-14 right-3"
            sx={{
              borderRadius: "5px",
              padding: "5px 5px",
              fontSize: "10px",
              backgroundColor: "#f44336",
              ":hover": { backgroundColor: "#f44336" },
              position: "absolute",
            }}
            onClick={removeBoundary}
          >
            Remove Boundary
          </Button>
        )}

        <Tools
          clear={drawing}
          onDrawChange={setDrawing}
          onRadiusChange={onRadiusCheckBoxChange}
        />

        {mapCore && (
          <Map
            disableDefaultUI={true}
            fullscreenControl={false}
            streetViewControl={true}
            zoomControl={true}
            defaultCenter={center}
            defaultZoom={zoom}
            mapId={mapID}
            zoomControlOptions={{
              position: mapCore.ControlPosition.RIGHT_CENTER,
            }}
            streetViewControlOptions={{
              position: mapCore.ControlPosition.RIGHT_CENTER,
            }}
          >
            { markerLib && <CurrentLocationMarker markerLib={markerLib}></CurrentLocationMarker>}
            
            <NearbyPlacesFetcher
                placeType={searchText}
                onPlacesFetched={onPlacesFetched}
              />
            {hotels && (
              <LMGeojson geojson={hotels} selectBounds={selectBounds} />
            )}
            {memoizedPlaces.length && markerLib && (
              <DrawPlaces places={memoizedPlaces} markerLib={markerLib} />
            )}
            <DrawingOverlay
              drawing={drawing}
              onApply={handleApply}
              onCancel={handleCancel}
            />
            {path && path.length && (
              <Polyline
                path={path}
                onBoundsChange={handleBoundsChange}
                options={{
                  strokeColor: "blue",
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                }}
              />
            )}
          </Map>
        )}

        <BottomSheetTabs
          open={open}
          onClose={handleClose}
          onPlacesUpdate={setSearchText}
        />
      </div>
    </div>
  );
};

export default LMMap;
