import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  latitude: null,
  longitude: null,
  bounds: null,
  searchText: { text: "", type: "city" },
  selectedMainFilters: ["hotels"], // Allow multiple filters to be selected at once
  sort: "popularity",
  page_number: 0,
  filters: {
    hotels: {
      adults: 1,
      children: 1,
      rooms: 1,
      startDate: dayjs().toString(),
      endDate: dayjs().add(1, "day").toString(),
      minBudget: 0,
      maxBudget: 2000,
      categories_filter_ids: "property_type::204",
    },
    shortTerm: {
      adults: 1,
      children: 1,
      rooms: 1,
      startDate: dayjs().toString(),
      endDate: dayjs().add(1, "day").toString(),
      minBudget: 0,
      maxBudget: 2000,
      categories_filter_ids:
        "property_type::201,property_type::206,property_type::213",
    },
    monthlyStay: {
      adults: 1,
      children: 1,
      rooms: 1,
      startDate: dayjs().toString(),
      endDate: dayjs().add(1, "day").toString(),
      minBudget: 0,
      maxBudget: 2000,
    },
    rent: {
      adults: 1,
      children: 1,
      rooms: 1,
      minPrice: 0,
      maxPrice: 3000,
    },
    buy: {
      adults: 1,
      children: 1,
      rooms: 1,
      minPrice: 0,
      maxPrice: 200000,
    },
  },
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setMainFilter(state, action) {
      if (!state.selectedMainFilters.includes(action.payload)) {
        state.selectedMainFilters.push(action.payload);
      }
    },
    removeMainFilter(state, action) {
      state.selectedMainFilters = state.selectedMainFilters.filter(
        (filter) => filter !== action.payload
      );
    },
    setHotelFilters(state, action) {
      state.filters = {
        ...state.filters,
        hotels: { ...state.filters.hotels, ...action.payload },
      };
    },
    setShortTermFilters(state, action) {
      state.filters = {
        ...state.filters,
        shortTerm: { ...state.filters.shortTerm, ...action.payload },
      };
    },
    setMonthlyFilters(state, action) {
      state.filters = {
        ...state.filters,
        monthlyStay: { ...state.filters.monthlyStay, ...action.payload },
      };
    },
    setRentFilters(state, action) {
      state.filters.rent = {
        ...state.filters.rent,
        ...action.payload,
      };
    },
    setBuyFilters(state, action) {
      state.filters.buy = {
        ...state.filters.buy,
        ...action.payload,
      };
    },
    clearHotelFilters(state) {
      state.filters = {
        ...state.filters,
        hotels: {
          adults: 1,
          children: 1,
          rooms: 1,
          startDate: dayjs().toString(),
          endDate: dayjs().add(1, "day").toString(),
          minBudget: 500,
          maxBudget: 1000,
          categories_filter_ids: "property_type::204",
        },
      };
      state.selectedMainFilters = state.selectedMainFilters.filter(
        (filter) => filter !== "hotels"
      );
      console.log(
        "clearrrrrrrr",
        state.selectedMainFilters.filter((filter) => filter !== "hotels")
      );
    },
    clearShortTermFilters(state) {
      state.filters.shortTerm = {
        adults: 3,
        children: 3,
        rooms: 2,
        minBudget: 50,
        maxBudget: 200,
      };
      state.selectedMainFilters = state.selectedMainFilters.filter(
        (filter) => filter !== "shortTerm"
      );
    },
    clearRentFilters(state) {
      state.filters.rent = {
        adults: 3,
        children: 3,
        rooms: 2,
        minBudget: 50,
        maxBudget: 200,
      };
      state.selectedMainFilters = state.selectedMainFilters.filter(
        (filter) => filter !== "rent"
      );
    },
    clearBuyFilters(state) {
      state.filters.buy = {
        adults: 1,
        children: 0,
        rooms: 1,
        minBudget: 50,
        maxBudget: 200,
      };
      state.selectedMainFilters = state.selectedMainFilters.filter(
        (filter) => filter !== "buy"
      );
    },
    setCoordinates: (state, action) => {
      state.latitude = action.payload.lat;
      state.longitude = action.payload.lng;
      state.searchText = action.payload?.searchText || "";
      if (action?.payload?.isRefetchAll) {
        state.filters = {
          ...state.filters,
          hotels: { ...state.filters.hotels, isRefatched: true },
          shortTerm: { ...state.filters.shortTerm, isRefatched: true },
          rent: { ...state.filters.rent, isRefatched: true },
          buy: { ...state.filters.buy, isRefatched: true },
        };
      }
    },

    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setBounds: (state, action) => {
      state.bounds = action.payload;
    },
  },
});

export const {
  setMainFilter,
  removeMainFilter,
  setHotelFilters,
  setShortTermFilters,
  setMonthlyFilters,
  setRentFilters,
  setBuyFilters,
  clearHotelFilters,
  clearShortTermFilters,
  clearRentFilters,
  clearBuyFilters,
  setCoordinates,
  setSort,
  setBounds,
} = filterSlice.actions;

export default filterSlice.reducer;
