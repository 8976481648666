import { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { setCurrentLocationAction } from "../store/Map/mapActions";

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

export const usePosition = (watch = false, userSettings = {}) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const prevPositionRef = useRef(position);

  const settings = useMemo(
    () => ({
      ...defaultSettings,
      ...userSettings,
    }),
    [userSettings]
  );

  const onChange = (pos) => {
    console.log("posssss", pos);
    const newPosition = {
      latitude: pos.coords.latitude,
      longitude: pos.coords.longitude,
      accuracy: pos.coords.accuracy,
      speed: pos.coords.speed,
      heading: pos.coords.heading,
      timestamp: pos.timestamp,
    };

    if (
      prevPositionRef.current.latitude !== newPosition.latitude ||
      prevPositionRef.current.longitude !== newPosition.longitude
    ) {
      setPosition(newPosition);
      setCurrentLocationAction(dispatch, newPosition);
      prevPositionRef.current = newPosition;
    }
  };

  const onError = (err) => {
    setError(err.message);
  };

  useEffect(() => {
    if (!navigator || !navigator.geolocation) {
      setError("Geolocation is not supported");
      return;
    }

    let watcher;

    if (watch) {
      watcher = navigator.geolocation.watchPosition(
        onChange,
        onError,
        settings
      );
    } else {
      navigator.geolocation.getCurrentPosition(onChange, onError, settings);
    }

    return () => {
      if (watcher) {
        navigator.geolocation.clearWatch(watcher);
      }
    };
  }, [watch, settings]);

  return { ...position, error };
};

export default usePosition;
