import { configureStore } from "@reduxjs/toolkit";
import authReducers from "@/store/Auth/authReducers";
import mapReducers from "@/store/Map/mapReducers";
import listingReducers from "@/store/Listing/listingReducers";
import filterReducers from "./Filter/filterReducers";
import layoutReducers from "./Layout/layoutReducers";
import userReducers from "./User/userReducers";

const store = configureStore({
  reducer: {
    auth: authReducers,
    map: mapReducers,
    hotels: listingReducers,
    filter: filterReducers,
    layout: layoutReducers,
    user: userReducers,
  },
});
export default store;
