import React from "react";
import { Chip } from "@mui/material";
import { hotelFacilitiesMapping, rentBuyTags } from "./facilityMapping";

const ChipContainer = ({ details, listingDetail }) => {
  const { accommodationType } = details?.properties || {};
  const hotel_facilities = listingDetail?.hotel_facilities;

  const facility = hotel_facilities ? hotel_facilities.split(",") : [];

  return (
    <div className="overflow-x-auto hide-scrollbar max-w-[450px] absolute bottom-[91px]">
      <div className="flex ">
        {accommodationType === "rent" || accommodationType === "sell"
          ? rentBuyTags.map((chip, index) => {
              if (!details?.properties?.[chip]) {
                return;
              }
              return (
                <Chip
                  key={index}
                  label={`${chip} ${details?.properties?.[chip]}`}
                  sx={{ margin: "0 5px" }}
                  className="bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] !text-[#fff]"
                />
              );
            })
          : facility.map((chip, index) => {
              if (!hotelFacilitiesMapping?.[chip]) {
                return;
              }
              return (
                <Chip
                  key={index}
                  label={hotelFacilitiesMapping?.[chip] || ""}
                  sx={{ margin: "0 5px" }}
                  className="bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] !text-[#fff]"
                />
              );
            })}
      </div>
    </div>
  );
};
export default ChipContainer;
