import { Button } from "@mui/material";
import { useMap } from "@vis.gl/react-google-maps";
import React, { useState, useRef, useEffect } from "react";

const getDistance = (p1, p2) => {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
};

const getPerpendicularDistance = (point, lineStart, lineEnd) => {
  const area = Math.abs(
    lineStart.x * lineEnd.y +
      lineEnd.x * point.y +
      point.x * lineStart.y -
      (lineEnd.x * lineStart.y + point.x * lineEnd.y + lineStart.x * point.y)
  );
  const bottom = getDistance(lineStart, lineEnd);
  return (area / bottom) * 2;
};

const ramerDouglasPeucker = (points, epsilon) => {
  if (points.length < 3) return points;

  let dmax = 0;
  let index = 0;

  for (let i = 1; i < points.length - 1; i++) {
    const d = getPerpendicularDistance(
      points[i],
      points[0],
      points[points.length - 1]
    );
    if (d > dmax) {
      index = i;
      dmax = d;
    }
  }

  if (dmax >= epsilon) {
    const firstHalf = ramerDouglasPeucker(points.slice(0, index + 1), epsilon);
    const secondHalf = ramerDouglasPeucker(points.slice(index), epsilon);

    return firstHalf.slice(0, -1).concat(secondHalf);
  } else {
    return [points[0], points[points.length - 1]];
  }
};

const simplifyPath = (points, tolerance = 2) => {
  return ramerDouglasPeucker(points, tolerance);
};

const DrawingOverlay = ({ onApply, onCancel, drawing }) => {
  const [paths, setPaths] = useState([]);
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const isDrawingRef = useRef(false);
  const map = useMap();

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.lineWidth = 2;
      ctx.strokeStyle = "blue";
      ctxRef.current = ctx;
    }
  }, [drawing]);

  const getRelativePosition = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();

    const x = e.clientX || (e.touches && e.touches[0].clientX); // For touch events
    const y = e.clientY || (e.touches && e.touches[0].clientY); // For touch events

    return {
      x: x - rect.left,
      y: y - rect.top,
    };
  };

  const onStart = (e) => {
    if (drawing && ctxRef.current) {
      const { x, y } = getRelativePosition(e);
      isDrawingRef.current = true;
      ctxRef.current.beginPath();
      ctxRef.current.moveTo(x, y);
      setPaths([{ x, y }]);
    }
  };

  const onMove = (e) => {
    if (isDrawingRef.current && ctxRef.current) {
      const { x, y } = getRelativePosition(e);
      ctxRef.current.lineTo(x, y);
      ctxRef.current.stroke();
      setPaths((prevPaths) => [...prevPaths, { x, y }]);
    }
  };

  const onEnd = () => {
    if (drawing && ctxRef.current) {
      isDrawingRef.current = false;
      ctxRef.current.closePath();
    }
  };

  const clearCanvas = () => {
    if (canvasRef.current && ctxRef.current) {
      const canvas = canvasRef.current;
      ctxRef.current.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const convertToLatLng = (points) => {
    if (!map || !window.google) return;

    const projection = map.getProjection();
    const bounds = map.getBounds();
    const zoom = map.getZoom();
    const scale = Math.pow(2, zoom);

    const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
    const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
    const worldWidth = Math.abs(topRight.x - bottomLeft.x);

    return points.map(({ x, y }) => {
      const pointRatio = {
        x: x / (canvasRef.current.width / worldWidth),
        y: y / (canvasRef.current.height / (bottomLeft.y - topRight.y)),
      };
      const worldPoint = new window.google.maps.Point(
        bottomLeft.x + pointRatio.x,
        topRight.y + pointRatio.y
      );

      const latLng = projection.fromPointToLatLng(worldPoint);
      return { lat: latLng.lat(), lng: latLng.lng() };
    });
  };

  const closePathIfOpen = (path) => {
    if (path.length > 2) {
      const start = path[0];
      const end = path[path.length - 1];
      const distance = getDistance(start, end);

      // If the start and end points are not the same, close the path
      if (distance > 5) {
        path.push(start);
      }
    }
    return path;
  };

  useEffect(() => {
    if (!drawing) {
      clearCanvas();
    }
  }, [drawing]);

  return (
    <>
      {drawing && (
        <canvas
          ref={canvasRef}
          onMouseDown={onStart}
          onMouseMove={onMove}
          onMouseUp={onEnd}
          onTouchStart={onStart}  // Add touch event handlers
          onTouchMove={onMove}    // Add touch event handlers
          onTouchEnd={onEnd}      // Add touch event handlers
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Semi-transparent overlay
            cursor: "crosshair",
            zIndex: 1000, // Ensure it's above other elements
          }}
          width={canvasRef.current?.parentElement?.clientWidth || 800}
          height={canvasRef.current?.parentElement?.clientHeight || 600}
        />
      )}

      {drawing && (
        <div style={{ position: "absolute", top: 45, right: 10, zIndex: 1001 }}>
          <Button
            variant="contained"
            sx={{
              borderRadius: "5px",
              padding: "5px 5px",
              fontSize: "10px",
              marginRight: "4px",
              backgroundColor: "#4A90E2",
              ":hover": {
                backgroundColor: "#4A90E2",
              },
            }}
            onClick={() => {
              let simplifiedPath = simplifyPath(paths, 5); // Adjust tolerance as needed
              simplifiedPath = closePathIfOpen(simplifiedPath);
              const latLngPath = convertToLatLng(simplifiedPath);
              onApply(latLngPath);
              setPaths([]);
              clearCanvas();
            }}

          >
            Apply
          </Button>
          <Button
            variant="contained"
            sx={{
              borderRadius: "5px",
              padding: "5px 5px",
              fontSize: "10px",
              backgroundColor: "#f44336",
              ":hover": {
                backgroundColor: "#f44336",
              },
            }}
            onClick={() => {
              onCancel();
              setPaths([]);
              clearCanvas();
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default DrawingOverlay;
