import React, { useState, useEffect, useRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import mapIcon from "../../assets/icons/svg/map-icon.svg";
import DirectionsIcon from "@mui/icons-material/Directions";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import PushPinIcon from "@mui/icons-material/PushPin";

const HotelListItem = ({ detail, onDelete }) => {
  const [isSwiped, setIsSwiped] = useState(false); // To track swipe state
  const [isHovered, setIsHovered] = useState(false); // To track hover state (desktop)
  const itemRef = useRef(null);

  const {
    name,
    price,
    image,
    distance_cc,
    review_score,
    review_word,
    total_review,
    description,
    listingId,
  } = detail;

  // Detect click outside the delete icon
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (itemRef.current && !itemRef.current.contains(event.target)) {
        setIsSwiped(false); // Reset swipe when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [itemRef]);

  // Swipe gesture for mobile
  const handleTouchStart = (e) => {
    setIsSwiped(true);
  };

  // Hover for desktop
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      ref={itemRef}
      className={`flex space-x-4 py-4 border rounded-md shadow-sm w-full transition-transform duration-300
        ${isSwiped ? "transform -translate-x-20" : ""}
      `}
      onTouchStart={handleTouchStart} // Detect swipe on mobile
      onMouseEnter={handleMouseEnter} // Show delete icon on hover (desktop)
      onMouseLeave={handleMouseLeave}
    >
      <div className="relative">
        <img
          src={image}
          alt="Property"
          className="w-20 h-20 rounded-lg object-cover"
        />
        <div className="absolute top-0 left-0">
          <img src={mapIcon} alt="map icon" />
        </div>
      </div>
      <div className="w-full">
        <div className="flex justify-between w-full">
          <div>{name}</div>
          <div className="flex items-center justify-center w-8 h-8 rounded-md bg-[#2267EA] text-[#fff] text-base font-medium">
            {review_score}
          </div>
        </div>
        <div className="flex justify-between w-full mb-2">
          <div className="flex items-center space-x-1">
            <DirectionsIcon className="text-primary" />
            <span className="text-secondary-light text-[14px]">
              {distance_cc} from center
            </span>
          </div>
          <div>
            <div className="text-[#2B1A1A] font-normal text-[12px] text-right">
              {review_word}
            </div>
            <div className="font-extralight text-[#2B1A1A] text-[12px]">
              {total_review} reviews
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div>
            <div>
              <div className="text-[#2B1A1A] font-normal">
                € {Math.round(price)}
              </div>
              <div className="font-extralight text-[#2B1A1A] text-[14px]">
                {description}
              </div>
            </div>
          </div>
          <div className="flex space-x-1">
            {[...Array(detail.class)].map((_, index) => (
              <StarIcon
                key={index}
                className="text-warn"
                sx={{ fontSize: "16px" }}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-end w-full">
          <Button
            sx={{ borderRadius: "6px" }}
            variant="contained"
            color="primary"
            className="text-secondary-l0 bg-primary !px-2 !py-1 !text-[10px]"
          >
            See availability
          </Button>
        </div>
      </div>
      {(isSwiped || isHovered) && ( // Show delete icon on swipe (mobile) or hover (desktop)
        <div className="flex items-center justify-center bg-red-500 p-2 rounded-full cursor-pointer">
          <DeleteIcon
            onClick={() => onDelete(listingId)}
            className="text-white"
          />
          <PushPinIcon className="text-white" />
        </div>
      )}
    </div>
  );
};

export default HotelListItem;
