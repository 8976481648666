import { Directions, LocalPhone } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import IconCircular from "../../components/IconCircular/IconCircular";

const InfoBuyRentCard = ({ details }) => {
  const {
    price,
    thumbnail,
    address,
    accommodationType,
    listingKey,
    propertyName,
  } = details?.properties || {};
  const filterStyle = {
    rent: { borderClr: "#94DC20", text: "For Rent" },
    buy: { borderClr: "#DC8520", text: "For Sale" },
  };
  console.log("listingkeeeeeeeee", listingKey);
  return (
    <div className=" px-2 pb-1 bg-white shadow-md rounded-lg w-full">
      {/* Left Section: Hotel image, name, and rating */}
      <div className="flex items-center w-full">
        {/* Hotel Image */}
        <img
          className="w-16 h-16 rounded-md"
          src={thumbnail}
          alt="Hotel"
          style={{
            border: `2px solid ${filterStyle?.[listingKey]?.borderClr}`,
          }}
        />
        {/* Hotel Name, Ratings, and Reviews */}
        <div className="w-[80%]">
          <div className="flex justify-between items-center mb-2">
            <div
              style={{ background: `${filterStyle?.[listingKey]?.borderClr}` }}
              className={`text-[#fff] !rounded-l-none rounded-md text-center text-xs py-1 px-1`}
            >
              {filterStyle?.[listingKey]?.text}
            </div>
            <div className="flex items-center space-x-2">
              <div>
                {" "}
                <span
                  style={{ color: `${filterStyle?.[listingKey]?.borderClr}` }}
                  className={`text-xl font-bold `}
                >
                  € {Math.floor(price)} / month
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="!text-[#2E6ADC] text-base font-normal">
              {propertyName}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center w-[95%]">
        <div className="flex items-center my-2">
          <div className="text-xs !text-[#000]/50">
            <div className="w-10 h-10">
              <IconCircular
                alt={"locaton"}
                key={"location"}
                src={"https://picsum.photos/200?random=5"}
              />
            </div>
          </div>
          <div className="text-xs !text-[#000] mx-1">900 m</div>
          <div className="text-xs !text-[#000]/50">to my office</div>
          <div className="flex items-center">
            <Directions sx={{ color: "#1289F6" }} />
          </div>
        </div>
        <div className="flex items-center space-x-2 rounded-full p-1 w-6 h-6 bg-[#1289F6]">
          <LocalPhone sx={{ color: "#fff" }} />
        </div>
      </div>
    </div>
  );
};
export default InfoBuyRentCard;
