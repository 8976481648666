import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadingStore: null,
  userStore: {
    favouriteList: [],
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserStore: (state, action) => {
      state.userStore = { ...state.userStore, ...action.payload };
    },
    setRequestStatus: (state, action) => {
      state.loadingStore = { ...state.loadingStore, ...action.payload };
    },
  },
});
export const { setUserStore, setRequestStatus } = userSlice.actions;
export default userSlice.reducer;
