import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setMainFilter,
  setHotelFilters,
  clearHotelFilters,
  setShortTermFilters,
  clearShortTermFilters,
  setRentFilters,
  clearRentFilters,
  setBuyFilters,
  clearBuyFilters,
} from "@/store/Filter/filterReducers";
import FilterButton from "./FilterButton";
import DateRangePick from "../../DateRangePick";
import BudgetRange from "../../BudgetRange";
import OccupancyForm from "../../OccupancyForm";
import dayjs from "dayjs";
import AdvanceFilter from "./AdvanceFilter";
import RentBuyAdvanceFilter from "./RentBuyAdvanceFilter";
import { getCityAndCountry } from "../../../common/Helpers";
import { setMonthlyFilters } from "../../../store/Filter/filterReducers";

const AccommodationFilter = () => {
  const dispatch = useDispatch();

  const selectedMainFilters = useSelector(
    (state) => state.filter.selectedMainFilters || []
  );
  const searchText = useSelector((state) => state.filter.searchText || "");
  const filters = useSelector((state) => state.filter.filters || {});
  const [appliedFilters, setAppliedFilters] = useState(() => {
    const initialFilters = {};
    if (selectedMainFilters.includes("hotels")) {
      initialFilters.hotels = true;
    }
    return initialFilters;
  });

  useEffect(() => {
    if (!selectedMainFilters || selectedMainFilters?.length <= 0) {
      console.log("apply filter again");
      dispatch(setMainFilter("hotels"));
      setAppliedFilters((prev) => ({ ...prev, hotels: true }));
    }
  }, [dispatch, selectedMainFilters]);

  const handleApply = useCallback(
    (filterKey, values) => {
      const actionMap = {
        hotels: setHotelFilters,
        shortTerm: setShortTermFilters,
        monthlyStay: setMonthlyFilters,
        rent: setRentFilters,
        buy: setBuyFilters,
      };
      const action = actionMap[filterKey];
      if (action) {
        dispatch(action({ ...values, isRefatched: true }));
        dispatch(setMainFilter(filterKey));
        setAppliedFilters((prev) => ({ ...prev, [filterKey]: true }));
      }
    },
    [dispatch]
  );

  const handleClear = useCallback(
    (filterKey) => {
      const actionMap = {
        hotels: clearHotelFilters,
        shortTerm: clearShortTermFilters,
        rent: clearRentFilters,
        buy: clearBuyFilters,
      };
      const action = actionMap[filterKey];
      if (action) {
        dispatch(action());
        setAppliedFilters((prev) => ({ ...prev, [filterKey]: false }));
      }
    },
    [dispatch]
  );

  return (
    <div className="flex flex-row gap-1 flex-nowrap overflow-x-auto hide-scrollbar">
      <FilterButton
        label="Daily stays"
        filterKey="hotels"
        initialValues={filters.hotels}
        onApply={handleApply}
        onClear={handleClear}
        applied={appliedFilters.hotels}
        setAppliedFilters={setAppliedFilters}
      >
        <div className="text-center mb-3 !text-[#fff]">
          Daily stays filter by
        </div>
        <div className="text-center mb-3 text-xs !text-[#C4FF47]">
          Must-Have Filters
        </div>
        <DateRangePick
          initialValue={[
            dayjs(filters.hotels?.startDate),
            dayjs(filters.hotels?.endDate),
          ]}
        />
        <OccupancyForm
          initialOccupancy={{
            adults: filters.hotels?.adults || 1,
            children: filters.hotels?.children || 0,
            rooms: filters.hotels?.rooms || 1,
          }}
        />
        <BudgetRange
          maxValue={2000}
          minValue={0}
          filterKey="hotels"
          minKey={"minBudget"}
          maxKey={"maxBudget"}
          title={"My Budget (per night)"}
          initialValue={[filters.hotels?.minBudget, filters.hotels?.maxBudget]}
        />

        <AdvanceFilter filterKey="hotels" />
      </FilterButton>

      {/* Repeat similar structure for other filters */}
      <FilterButton
        label="6+ Days Stay"
        filterKey="shortTerm"
        initialValues={filters.shortTerm}
        onApply={handleApply}
        onClear={handleClear}
        applied={appliedFilters.shortTerm}
        setAppliedFilters={setAppliedFilters}
      >
        <div className="text-center pb-1">Short term filter by</div>
        <div className="text-center pb-1 text-xs !text-[#C4FF47]">
          Must-Have Filters
        </div>
        <DateRangePick
          initialValue={[
            dayjs(filters.shortTerm?.startDate),
            dayjs(filters.shortTerm?.endDate),
          ]}
        />
        <OccupancyForm
          initialOccupancy={{
            adults: filters.shortTerm?.adults || 1,
            children: filters.shortTerm?.children || 0,
            rooms: filters.shortTerm?.rooms || 1,
          }}
        />
        <BudgetRange
          maxValue={2000}
          minValue={0}
          filterKey="shortTerm"
          minKey={"minBudget"}
          maxKey={"maxBudget"}
          title={"My Budget (per night)"}
          initialValue={[
            filters.shortTerm?.minBudget,
            filters.shortTerm?.maxBudget,
          ]}
        />
        <AdvanceFilter filterKey="shortTerm" />
      </FilterButton>
      <FilterButton
        label="Monthly Stay"
        filterKey="monthlyStay"
        initialValues={filters.monthlyStay}
        onApply={handleApply}
        onClear={handleClear}
        applied={appliedFilters.monthlyStay}
        setAppliedFilters={setAppliedFilters}
      >
        <div className="text-center pb-1">Short term filter by</div>
        <div className="text-center pb-1 text-xs !text-[#C4FF47]">
          Must-Have Filters
        </div>
        <DateRangePick
          initialValue={[
            dayjs(filters.monthlyStay?.startDate),
            dayjs(filters.monthlyStay?.endDate),
          ]}
        />
        <OccupancyForm
          initialOccupancy={{
            adults: filters.monthlyStay?.adults || 1,
            children: filters.monthlyStay?.children || 0,
            rooms: filters.monthlyStay?.rooms || 1,
          }}
        />
        <BudgetRange
          maxValue={2000}
          minValue={0}
          filterKey="monthlyStay"
          minKey={"minBudget"}
          maxKey={"maxBudget"}
          title={"My Budget (per night)"}
          initialValue={[
            filters.monthlyStay?.minBudget,
            filters.monthlyStay?.maxBudget,
          ]}
        />
        <AdvanceFilter filterKey="monthlyStay" />
      </FilterButton>

      <FilterButton
        label="Yearly Rent"
        filterKey="rent"
        initialValues={filters.rent}
        onApply={handleApply}
        onClear={handleClear}
        applied={appliedFilters.rent}
        setAppliedFilters={setAppliedFilters}
        //isDisabled={getCityAndCountry(searchText?.text)?.isNotAvailable}
      >
        <RentBuyAdvanceFilter
          minPrice={0}
          maxPrice={3000}
          filterKey="rent"
          initialFilters={filters}
        />
      </FilterButton>

      <FilterButton
        label="Buy"
        filterKey="buy"
        initialValues={filters.buy}
        onApply={handleApply}
        onClear={handleClear}
        applied={appliedFilters.buy}
        setAppliedFilters={setAppliedFilters}
        //isDisabled={getCityAndCountry(searchText?.text)?.isNotAvailable}
      >
        <RentBuyAdvanceFilter
          minPrice={0}
          maxPrice={200000}
          filterKey="buy"
          initialFilters={filters}
        />
      </FilterButton>
    </div>
  );
};

export default AccommodationFilter;
