import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import usePosition from "../../hooks/usePosition";
import { setCoordinates } from "../../store/Filter/filterReducers";
import { APIProvider } from "@vis.gl/react-google-maps";
import LMMap from "../../components/LeftCol/Map/Map";
import useFilter from "../../hooks/useFilter";
import { setCenterAction } from "../../store/Map/mapActions";
import Swipe from "../Swipe/Swipe";
import FilterTabs from "../Saved/FilterTabs";
import List from "../Saved/List";
import useSupabase from "../../hooks/useSupabase";
import { getCurrentUser } from "../../common/Helpers";

const apiKey = "AIzaSyDBfPZBgU_xAaRw_-0VU1hv1TyoYR_nV6E";

function Home() {
  const { fetchUser } = useSupabase();
  const [activeTab, setActiveTab] = useState("all");
  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  useFilter();

  const { latitude, longitude, error } = usePosition(false, {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 600000,
  });

  useEffect(() => {
    if (latitude && longitude) {
      dispatch(setCoordinates({ lat: latitude, lng: longitude }));
      setCenterAction(dispatch, { lat: latitude, lng: longitude });
    }
  }, [latitude, longitude]);

  useEffect(() => {
    if (!currentUser) {
      fetchUser();
    }
  }, []);

  function onActiveTab(value) {
    setActiveTab(value);
  }

  return (
    <div className="bg-[#7fa9db] w-full h-screen flex flex-row">
      <div className="h-[95%] bg-[#7fa9db] w-full flex flex-row items-center justify-center gap-2">
        <APIProvider apiKey={apiKey}>
          <LMMap />
        </APIProvider>
        <div className="flex flex-col w-full h-full gap-6 bg-secondary-l0 relative">
          <Swipe isMobileScreen={false} />
        </div>
        <div className="flex flex-col w-full h-full gap-6 bg-secondary-l0">
          <FilterTabs onActiveTab={onActiveTab} />
          <List activeTab={activeTab || "all"} />
        </div>
      </div>
    </div>
  );
}

export default Home;
