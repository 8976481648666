import React, { useState } from "react";
import Button from "@mui/material/Button";

const FilterTabs = ({ onActiveTab }) => {
  const [activeTab, setActiveTab] = useState("all"); // Default active tab

  const tabs = [
    { label: "All", value: "all" },
    { label: "Hotels", value: "Hotel" },
    { label: "Short term", value: "Shortterm" },
    { label: "For Rent", value: "rent" },
    { label: "For sale", value: "buy" },
  ];

  const handleTabChange = (value) => {
    setActiveTab(value);
    onActiveTab(value);
  };

  return (
    <div className="flex flex-row bg-secondary-l0 overflow-x-auto">
      {tabs.map((tab) => (
        <Button
          key={tab.label}
          className="px-4 py-2 text-sm font-medium flex flex-grow border-r-0"
          onClick={() => handleTabChange(tab.value)}
          sx={{
            backgroundColor: activeTab === tab.value ? "#FF7F50" : "#ffffff", // Orange for active, white for inactive
            color: activeTab === tab.value ? "#ffffff" : "#000000", // White text for active, black for inactive
            "&:hover": {
              backgroundColor: activeTab === tab.value ? "#FF6347" : "#f0f0f0", // Slightly darker orange on hover for active
            },
            borderRadius: "0px",
            border: activeTab === tab.value ? "none" : "1px solid #ccc", // Border for inactive
            textTransform: "none", // Prevents uppercase transformation
          }}
        >
          {tab.label}
        </Button>
      ))}
    </div>
  );
};

export default FilterTabs;
