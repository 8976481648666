import { Directions } from "@mui/icons-material";
import React from "react";

const InfoCard = ({ details, listingDetail }) => {
  const {
    price,
    thumbnail,
    propertyName,
    badge,
    review_score,
    review_nr,
    priceInfoContent,
    listingKey,
    accommodationType,
  } = details?.properties || {};
  const filterStyle = {
    rent: { borderClr: "#94DC20", text: "For Rent" },
    Hotel: { borderClr: "#5397FD", text: "Hotel" },
    Shortterm: { borderClr: "#4a90e2", text: "Short Term" },
    monthlyStay: { borderClr: "#FF5139", text: "Monthly stay" },
    buy: { borderClr: "#DC8520", text: "For Sale" },
  };
  return (
    <div className=" px-2 pb-1 bg-white shadow-md rounded-lg w-full">
      {/* Left Section: Hotel image, name, and rating */}
      <div className="flex items-center w-full">
        {/* Hotel Image */}
        <img
          className="w-16 h-16 rounded-md"
          src={thumbnail}
          alt="Hotel"
          style={{
            border: `2px solid ${filterStyle?.[listingKey]?.borderClr}`,
          }}
        />
        {/* Hotel Name, Ratings, and Reviews */}
        <div className="w-[80%]">
          <div className="flex justify-between items-center mb-2">
            <div
              style={{ background: `${filterStyle?.[listingKey]?.borderClr}` }}
              className={`  text-[#fff] !rounded-l-none rounded-md text-center text-xs py-1 px-1`}
            >
              {filterStyle?.[listingKey]?.text}
            </div>
            <div className="flex items-center space-x-2">
              {listingKey === "Hotel" && (
                <div className="text-[#000]/50 text-[12px]">
                  {priceInfoContent}
                </div>
              )}
              <div>
                {" "}
                <span
                  style={{ color: `${filterStyle?.[listingKey]?.borderClr}` }}
                  className={`text-xl font-bold`}
                >
                  {accommodationType === "monthlyStay"
                    ? price
                    : `€ ${Math.round(price)}`}
                </span>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="!text-[#2E6ADC] text-base font-normal">
              {propertyName}
            </div>
            <div className="flex items-center">
              {details?.properties?.class && (
                <div className="text-yellow-400 flex items-center">
                  {[...Array(details?.properties?.class || 0)].map((_, i) => (
                    <span key={i} className="text-[#F4BB3F]">
                      ★
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center w-[95%]">
        <div className="flex items-center">
          <div className="flex items-center">
            <Directions sx={{ color: "#1289F6" }} />
          </div>
          <div className="text-xs !text-[#000]/50">
            {Math.round(listingDetail?.distance_to_cc)} km from center
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <div>
            <div className="text-base font-medium">{badge}</div>
            <div className="text-xs !text-[#000]/50">{review_nr} reviews</div>
          </div>
          <div className="!bg-[#1E4BB1] !text-[#fff] px-2 py-1 text-sm font-bold rounded-md">
            {review_score}
          </div>
        </div>
      </div>
    </div>
  );
};
export default InfoCard;
