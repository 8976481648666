import dayjs from "dayjs";

export function getHashParams() {
  const hash = window.location.hash.substring(1); // Remove the leading #
  const params = new URLSearchParams(hash);
  return {
    accessToken: params.get("access_token"),
    expiresAt: params.get("expires_at"),
    expiresIn: params.get("expires_in"),
    refreshToken: params.get("refresh_token"),
    tokenType: params.get("token_type"),
    type: params.get("type"),
  };
}

export function getStayDuration(checkinDate, checkoutDate) {
  const checkin = dayjs(checkinDate);
  const checkout = dayjs(checkoutDate);

  // Calculate the total number of days
  const totalDays = checkout.diff(checkin, "day");

  if (totalDays < 7) {
    return `${totalDays} day${totalDays > 1 ? "s" : ""}`;
  } else {
    const weeks = Math.floor(totalDays / 7);
    const days = totalDays % 7;

    if (days === 0) {
      return `${weeks} week${weeks > 1 ? "s" : ""}`;
    } else {
      return `${weeks} week${weeks > 1 ? "s" : ""} and ${days} day${
        days > 1 ? "s" : ""
      }`;
    }
  }
}
export function isGreaterDate(start, end) {
  const startDate = dayjs(start); // Start date
  const endDate = dayjs(end); // End date
  return startDate.isAfter(endDate);
}
export function listingDataMap(dataObj) {
  if (dataObj?.operation === "rent" || dataObj?.operation === "sale") {
    return {
      ...dataObj,
      propertyName: dataObj?.suggestedTexts?.title,
      accommodationTypeName: dataObj?.propertyType,
      price: dataObj?.price,
      thumbnail: dataObj?.thumbnail,
      accommodationType: dataObj?.operation === "sale" ? "buy" : "rent",
      id: dataObj?.propertyCode,
      city_in_trans: dataObj?.province,
      distance_to_cc: dataObj?.district,
      badge: dataObj?.status,
      review_score: dataObj?.review_score,
      address: dataObj?.address,
      review_score_word: dataObj?.review_score_word,
      review_nr: dataObj?.review_nr,
      images: dataObj?.multimedia?.images,
      unit_configuration_label: `<b>${dataObj?.detailedType?.typology} – ${dataObj?.size} m²</b>: ${dataObj?.rooms} rooms • ${dataObj?.bathrooms} bathroom`,
    };
  }
  console.log(
    "dataObj<<<<<<",
    dataObj?.pricingQuote?.structuredStayDisplayPrice?.primaryLine?.price
  );
  if (dataObj?.accommodation_type_name === "monthlyStay") {
    return {
      ...dataObj,
      propertyName: dataObj?.listing?.title,
      accommodationTypeName: dataObj?.accommodation_type_name,
      price:
        dataObj?.pricingQuote?.structuredStayDisplayPrice?.primaryLine
          ?.discountedPrice ||
        dataObj?.pricingQuote?.structuredStayDisplayPrice?.primaryLine?.price,
      thumbnail: dataObj?.listing?.contextualPictures?.[0]?.picture,
      accommodationType: dataObj?.accommodation_type_name,
      id: dataObj?.listing?.id,
      city_in_trans: dataObj?.listing?.localizedCityName,
      distance_to_cc: dataObj?.structuredContent?.distance,
      badge: dataObj?.formattedBadges?.[0]?.text,
      review_score: dataObj?.listing?.avgRatingLocalized,
      address: dataObj?.address,
      review_score_word: dataObj?.listing?.avgRatingLocalized,
      review_nr: dataObj?.reviewsCount,
      images: dataObj?.listing?.contextualPictures,
    };
  }
  return {
    ...dataObj,
    propertyName: dataObj?.name,
    accommodationTypeName: dataObj?.accommodation_type_name,
    thumbnail: dataObj?.photoMainUrl || dataObj?.max_photo_ur || "",
    accommodationType: dataObj?.accommodation_type_name,
    id: dataObj?.id,
    city_in_trans: dataObj?.city_in_trans,
    distance_to_cc: `${dataObj?.distance_to_cc} km from centre`,
    unit_configuration_label: dataObj?.unit_configuration_label,
    class: dataObj?.accuratePropertyClass,
    badge: dataObj?.reviewScoreWord,
    review_score: dataObj?.reviewScore,
    review_score_word: dataObj?.reviewScoreWord,
    review_nr: dataObj?.reviewCount,
    address: dataObj?.address,
    distance_to_cc_formatted: dataObj?.distance_to_cc_formatted,
    price: dataObj?.priceBreakdown?.grossPrice?.value,
    priceInfoContent: dataObj?.priceDetails?.info,
  };
}
export function listingDetailMap(dataObj, listingType) {
  if (listingType === "rent" || listingType === "sale") {
    return {
      ...dataObj,
    };
  }
  return {
    ...dataObj,
  };
}
export function getCityAndCountry(locationString) {
  if (!locationString) {
    return { isNotAvailable: true };
  }
  const countryCodeMapping = {
    portugal: { code: "pt", capital: "lisbon" },
    italy: { code: "it", capital: "rome" },
    spain: { code: "es", capital: "madrid" },
  };
  let isNotAvailable = false;

  let [city, country] = locationString
    .split(",")
    .map((item) => item.trim().toLowerCase());

  // If only country is provided
  if (!country) {
    country = city; // If no city is provided, assume it's just the country
    city = countryCodeMapping[country]?.capital || "unknown"; // Get capital or 'unknown'
  }

  // Check if country is not in Portugal, Italy, or Spain
  if (!countryCodeMapping[country]) {
    isNotAvailable = true;
  }

  // Convert country to its code if available in the mapping
  const countryCode = countryCodeMapping[country]?.code || country;

  return { city, country: countryCode, isNotAvailable };
}
export function getCurrentUser() {
  let storedUser = localStorage.getItem("user");
  if (storedUser) {
    return JSON.parse(storedUser);
  }
  return storedUser;
}
