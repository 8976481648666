import React, { useState, useEffect } from "react";
import { Button, Popover, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Import the CloseIcon

const FilterButton = ({
  label,
  filterKey,
  initialValues = {},
  children,
  onApply,
  onClear,
  applied = false,
  setAppliedFilters,
  isDisabled,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tempValues, setTempValues] = useState(initialValues); // Temporary state for filter values

  useEffect(() => {
    setTempValues(initialValues); // Update temporary values when initial values change
  }, [initialValues]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    if (onApply) {
      onApply(filterKey, tempValues); // Apply the temporary values to the Redux store
    }
    setAppliedFilters((prev) => ({ ...prev, [filterKey]: true }));
    handleClose();
  };

  const handleClear = () => {
    if (onClear) {
      onClear(filterKey); // Clear the filter values in the Redux store
    }
    setTempValues(initialValues); // Reset temporary values to initial state
    setAppliedFilters((prev) => ({ ...prev, [filterKey]: false }));
    handleClose();
  };

  const handleChange = (newValues) => {
    setTempValues((prev) => ({
      ...prev,
      ...newValues,
    }));
  };

  const open = Boolean(anchorEl);
  const filterStyle = {
    rent: { popBg: `bg-[#96BB00]`, tabBg: "#96BB00" },
    monthlyStay: { popBg: `bg-[#F23C03]`, tabBg: "#F23C03" },
    hotels: { popBg: `bg-[#1B66FC]`, tabBg: "#1B66FC" },
    shortTerm: { popBg: `bg-[#4a90e2]`, tabBg: "#4a90e2" },
    buy: { popBg: `bg-[#F99500]`, tabBg: "#F99500" },
  };
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        className="flex flex-grow flex-nowrap"
        sx={{
          backgroundColor: open
            ? filterStyle?.[filterKey]?.tabBg
            : applied
            ? filterStyle?.[filterKey]?.tabBg
            : "#ffffff",
          color: open ? "#fff" : applied ? "#ffffff" : "black",
          "&:hover": {
            backgroundColor: "lightgray",
          },
          display: "inline-flex", // Set display to inline-flex for dynamic width
          alignItems: "center",
          cursor: "pointer",
          padding: "8px 12px",
          whiteSpace: "nowrap",
          cursor: "pointer", // Change cursor when disabled
        }}
        onClick={handleClick}
      >
        {label}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: "w-72 max-w-72 !max-h-[400px]",
        }}
      >
        <div
          className={`flex flex-col gap-3 ${filterStyle?.[filterKey]?.popBg} !text-[#fff]`}
          style={{ position: "relative", height: "400px" }} // Ensures consistent height for the popover
        >
          {/* Close Icon Button */}
          <IconButton
            onClick={handleClose} // Close the popover when clicked
            style={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "#fff", // Close icon color
              zIndex: 1, // Ensure it's on top of the content
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>

          {!isDisabled && (
            <React.Fragment>
              <div
                className="flex-grow overflow-auto p-3"
                style={{ marginBottom: "60px" }} // Space for fixed buttons
              >
                {React.Children.map(children, (child) =>
                  child && React.isValidElement(child)
                    ? React.cloneElement(child, {
                        onChange: handleChange, // Pass single onChange handler
                        value: tempValues,
                      })
                    : null
                )}
              </div>

              {/* Fixed position for Clear and Apply buttons */}
              <div
                className="flex justify-end gap-2 p-3"
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: filterStyle?.[filterKey]?.popBg, // Matches background color
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleClear}
                  sx={{
                    color: "#fff", // Text color
                    borderColor: "#fff", // Border color
                    fontSize: "0.75rem", // Smaller text size
                    padding: "4px 8px", // Smaller padding
                    "&:hover": {
                      borderColor: "#fff", // Border color on hover
                      backgroundColor: "rgba(255, 255, 255, 0.1)", // Optional: Light background on hover
                    },
                  }}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApply}
                  sx={{
                    fontSize: "0.75rem", // Smaller text size
                    padding: "4px 8px", // Smaller padding
                  }}
                >
                  Apply
                </Button>
              </div>
            </React.Fragment>
          )}

          {/* Conditionally render overlay when isDisabled is true */}
          {isDisabled && (
            <div className="flex justify-center items-center h-full px-2">
              Rent and Buy only available in Portugal, Spain and Italy.
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

export default FilterButton;
