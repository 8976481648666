import React from "react";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";

const ListingsButton = ({ total }) => {
  return (
    <Button
      className="flex flex-grow px-4 py-2 rounded-full shadow-xl max-w-48"
      sx={{
        backgroundColor: "#FFB84D", // Orange background
        color: "#000000", // White text
        "&:hover": {
          backgroundColor: "#FFA500", // Darker orange on hover
        },
        display: "flex",
        borderTopRightRadius: "100px",
        borderBottomRightRadius: "100px",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      startIcon={<HomeIcon />}
    >
      {total} all listings
    </Button>
  );
};

export default ListingsButton;
