import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SortIcon from "@mui/icons-material/Sort";
import { Popover, List, ListItemButton, ListItemText } from "@mui/material";
import { setSort } from "@/store/Filter/filterReducers"; // Import the setSort action

const SortButton = ({ buttonHeight }) => {
  const dispatch = useDispatch();
  const sortState = useSelector((state) => state.filter.sort);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(sortState);

  const options = [
    {
      name: "Distance from place of interest",
      id: "distance",
    },
    {
      id: "popularity",
      name: "Popularity",
    },
    {
      id: "class_descending",
      name: "Stars (5 to 0)",
    },
    {
      id: "class_ascending",
      name: "Stars (0 to 5)",
    },
    {
      id: "bayesian_review_score",
      name: "Guest review score",
    },
    {
      id: "price",
      name: "Price (low to high)",
    },
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    dispatch(setSort(option)); // Update the sort state in Redux
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "sort-popover" : undefined;

  return (
    <>
      <button
        onClick={handleClick}
        className="flex cursor-pointer flex-col items-center justify-center bg-[#4A90E2] text-secondary-l0 rounded-lg shadow-md border-none"
        style={{ height: buttonHeight }}
      >
        <SortIcon className="text-white" style={{ fontSize: "20px" }} />
      </button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: "#fff", // Semi-transparent background
              color: "white",
              padding: "10px 0",
              borderRadius: "8px",
            },
          },
        }}
      >
        <List>
          {options.map((option, index) => (
            <ListItemButton
              key={index}
              onClick={() => handleOptionClick(option.id)}
              sx={{
                ":hover": {
                  backgroundColor: "rgba(74, 144, 226, 0.8)",
                },
                justifyContent: "center", // Center the text in the button
                backgroundColor:
                  option.id === selectedOption ? "#4A90E2" : "#fff",
                color: option.id === selectedOption ? "#fff" : "#000",
              }}
            >
              <ListItemText
                primary={option.name}
                sx={{ textAlign: "center" }}
                primaryTypographyProps={{
                  fontSize: 10,
                }}
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default SortButton;
