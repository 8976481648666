import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHotelsAction,
  getShorttermAction,
  getRentAction,
  getBuyAction,
  getMonthlyStayAction,
} from "../store/Listing/listingAction";
import dayjs from "dayjs";
import { getCityAndCountry } from "../common/Helpers";

const useFilter = () => {
  const dispatch = useDispatch();
  const filterState = useSelector((state) => state.filter);
  const { hotels } = useSelector((state) => state.hotels);

  useEffect(() => {
    if (
      filterState?.latitude &&
      filterState?.longitude &&
      filterState?.selectedMainFilters?.length > 0
    ) {
      getProperties();
    }
  }, [filterState]);

  function getProperties() {
    const startDate = formatDate("hotels")?.startDate;
    const endDate = formatDate("hotels")?.endDate;
    const sStartDate = formatDate("shortTerm")?.startDate;
    const sEndDate = formatDate("shortTerm")?.endDate;
    const mStartDate = formatDate("monthlyStay")?.startDate;
    const mEndDate = formatDate("monthlyStay")?.endDate;
    const payload = {
      ...filterState,
      filters: {
        ...filterState.filters,
        hotels: { ...filterState.filters.hotels, startDate, endDate },
        shortTerm: {
          ...filterState.filters.shortTerm,
          startDate: sStartDate,
          endDate: sEndDate,
        },
        monthlyStay: {
          ...filterState.filters.monthlyStay,
          startDate: mStartDate,
          endDate: mEndDate,
        },
      },
    };
    console.log("payloadd>>>>>", payload);
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("hotels") &&
      payload?.filters?.hotels?.isRefatched
    ) {
      dispatch(getHotelsAction(payload));
    }
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("shortTerm") &&
      payload?.filters?.shortTerm?.isRefatched
    ) {
      dispatch(getShorttermAction(payload));
    }
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("monthlyStay") &&
      payload?.filters?.monthlyStay?.isRefatched
    ) {
      dispatch(getMonthlyStayAction(payload));
    }
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("rent") &&
      payload?.filters?.rent?.isRefatched
    ) {
      dispatch(
        getRentAction({
          ...payload,
          ...getCityAndCountry(payload?.searchText?.text),
        })
      );
    }
    if (
      payload?.searchText?.text &&
      payload.selectedMainFilters.includes("buy") &&
      payload?.filters?.buy?.isRefatched
    ) {
      dispatch(
        getBuyAction({
          ...payload,
          ...getCityAndCountry(payload?.searchText?.text),
        })
      );
    }
  }

  function formatDate(key) {
    const startDate = dayjs(filterState?.filters?.[key]?.startDate).format(
      "YYYY-MM-DD"
    );
    const endDate = dayjs(filterState?.filters?.[key]?.endDate).format(
      "YYYY-MM-DD"
    );
    return { startDate, endDate };
  }
};

export default useFilter;
