import React, { useEffect } from "react";
import ListItem from "./ListItem";
import ListingsButton from "./ListingsButton";
import HotelListItem from "./HotelListItem";
import mapIcon from "../../assets/icons/svg/map-icon.svg";
import sortIcon from "../../assets/icons/svg/sorting-icon.svg";
import { Search } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  getFavouriteList,
  deleteFavouriteItem,
} from "../../store/User/userAction";
import { getCurrentUser } from "../../common/Helpers";

const List = ({ activeTab }) => {
  const currentUser = getCurrentUser();
  const disptach = useDispatch();
  const { favouriteList } = useSelector((state) => state.user.userStore);

  useEffect(() => {
    if (currentUser?.id) {
      disptach(getFavouriteList(currentUser?.id));
    }
  }, []);

  function onFavDelete(listingId) {
    disptach(deleteFavouriteItem(currentUser?.id, listingId));
  }

  function onFilter() {
    if (activeTab === "all") {
      return favouriteList.items || [];
    }
    return (favouriteList?.items || []).filter(
      (li) => li.propertyType === activeTab
    );
  }
  return (
    <div className="p-4 relative">
      <div className="flex items-center mb-4 absolute left-0 -top-3 justify-between w-full">
        {/* Add other buttons/icons here, like the sort icon */}
        <ListingsButton total={onFilter()?.length} />
        <div className="flex items-center  gap-2  mr-4">
          <Search />
          <img className="w-8 h-8" src={mapIcon} alt="map icon" />
          <img className="w-[30px] h-[25px]" src={sortIcon} alt="sort icon" />
        </div>
      </div>

      <div className="space-y-4 mt-4 w-full">
        {(onFilter() || []).map((property) =>
          property?.propertyType === "Hotel" ||
          property?.propertyType === "Shortterm" ? (
            <HotelListItem
              key={property?.listingId}
              detail={property}
              onDelete={onFavDelete}
            />
          ) : (
            <ListItem key={property?.listingId} detail={property} />
          )
        )}
      </div>
    </div>
  );
};

export default List;
