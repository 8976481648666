import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";

const BottomMenu = ({ activeItem, filterStyle, isMobileScreen }) => {
  const layout = useSelector((state) => state.layout.col);
  const [activeSheet, setActiveSheet] = useState(null);

  const handleOpenSheet = (sheet) => {
    setActiveSheet(sheet);
  };

  const handleCloseSheet = () => {
    setActiveSheet(null);
  };

  const handleBackdropClick = (e) => {
    // If the user clicks directly on the backdrop (not the bottom sheet content), close the sheet.
    if (e.target.id === "backdrop") {
      handleCloseSheet();
    }
  };
  console.log("render menu>><", layout);
  return (
    <Fragment>
      {(layout === 1 || !isMobileScreen) && (
        <div
          className="flex flex-row justify-between text-center p-2 gap-3 overflow-x-scroll shrink-0 hide-scrollbar"
          style={{
            background:
              filterStyle?.[activeItem?.properties?.listingKey]?.bg ||
              filterStyle?.Hotel?.bg,
          }}
        >
          <button
            className={`border-none bg-none bg-transparent font-medium min-w-max text-[#fff] py-2 `}
            onClick={() => handleOpenSheet("overview")}
          >
            Overview
          </button>
          <button
            className="border-none bg-none bg-transparent font-medium min-w-max text-[#fff]"
            onClick={() => handleOpenSheet("info")}
          >
            Info and Price
          </button>
          <button
            className="border-none bg-none bg-transparent font-medium  min-w-max text-[#fff]"
            onClick={() => handleOpenSheet("facilities")}
          >
            Facilities
          </button>
          <button
            className="border-none bg-none bg-transparent font-medium min-w-max text-[#fff]"
            onClick={() => handleOpenSheet("What available")}
          >
            What available
          </button>
          <button
            className="border-none bg-none bg-transparent font-medium min-w-max text-[#fff]"
            onClick={() => handleOpenSheet("Costs & Fees")}
          >
            Costs & Fees
          </button>
        </div>
      )}
      {activeSheet && (
        <div
          id="backdrop"
          className="absolute inset-0 bg-secondary-dark bg-opacity-50 flex justify-center items-end"
          onClick={handleBackdropClick}
        >
          <div className="bg-secondary-l0 w-full h-1/2 p-4 rounded-t-lg z-10">
            <button
              className="text-warn-dark font-bold float-right"
              onClick={handleCloseSheet}
            >
              Close
            </button>
            {activeSheet === "overview" && <p>Overview content goes here.</p>}
            {activeSheet === "info" && <p>Info and Price content goes here.</p>}
            {activeSheet === "facilities" && (
              <p>Facilities content goes here.</p>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default BottomMenu;
