import React, { useEffect, useState } from "react";
import Slider, { SliderThumb } from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";
import IconCircular from "../IconCircular/IconCircular";
import { useDispatch, useSelector } from "react-redux";
import { setCoordinates } from "../../store/Filter/filterReducers";
import { useMap } from "@vis.gl/react-google-maps";
import LocationAutocomplete from "../Autocomplete/LocationAutocomplete";
import { setSelectedPlaceAction } from "@/store/Map/mapActions";

const StyledSlider = styled(Slider)({
  color: "#9FE330", // Track color (yellow)
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 50,
    top: "15px",
    backgroundColor: "#29ABD4", // Blue background color for the thumb
    borderRadius: "18px", // Rounded capsule shape
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)", // Subtle shadow effect
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "inherit",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 1,
    backgroundColor: "#DFD3D3", // Light gray rail color
  },
});

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <div>
      {React.cloneElement(children, {
        style: {
          ...children.props.style,
          position: "relative",
        },
      })}
      <div
        className="text-primary"
        style={{
          position: "absolute",
          fontWeight: "bold",
          top: "-25px",
          right: 0,
          textShadow: "1px 1px 2px black",
          whiteSpace: "nowrap",
        }}
      >
        {`${value} km`}
      </div>
    </div>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired,
};

export default function DistanceSlider({ onRadiusValue, onPlacesUpdate }) {
  const dispatch = useDispatch();
  const [myLocation, setLocations] = useState([
    {
      name: "Office",
      position: { lat: 38.7783746, lng: -9.1658837 },
      img: "https://picsum.photos/200?random=1",
    },
    {
      name: "Mom",
      position: { lat: 38.728623, lng: -9.150019 },
      img: "https://picsum.photos/200?random=2",
    },
    {
      name: "My Girlfriend",
      position: { lat: 41.1579438, lng: -8.629105299999999 },
      img: "https://picsum.photos/200?random=3",
    },
    {
      name: "My Uni",
      position: { lat: 38.725468, lng: -9.147945 },
      img: "https://picsum.photos/200?random=4",
    },
  ]);
  const map = useMap();
  const [value, setValue] = useState(7); // Default value of 3 km
  const [search, setSearch] = useState("");
  const [activeLocation, setActiveLocation] = useState(myLocation[0]);
  const [isShowSearch, setToggle] = useState(false);
  const [isShowMyLocation, setToggleLocation] = useState(false);
  const currentLocation = useSelector((state) => state.map?.currentLocation);

  useEffect(() => {
    getCircleBounds(map.getCenter().lat(), map.getCenter().lng(), value);
  }, []);
  useEffect(() => {
    const current = {
      name: "Current location",
      position: {
        lat: currentLocation?.latitude,
        lng: currentLocation?.longitude,
      },
      img: "https://picsum.photos/200?random=5",
    };
    setLocations([current, ...myLocation]);
    setActiveLocation(current);
  }, [currentLocation]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onSliderChangeCommited = (event, newValue) => {
    getCircleBounds(map.getCenter().lat(), map.getCenter().lng(), newValue);
  };

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const onPlaceSelect = (place) => {
    console.log(place);
    if (!place || !place.geometry || !place.geometry.location) return;
    const { lat, lng } = place.geometry.location;

    // Fit map bounds to the selected place
    map.fitBounds(place.geometry.viewport);
    setLocations([
      {
        name: place?.name,
        position: { lat: lat(), lng: lng() },
        img: place.photos?.[0].getUrl() || "https://picsum.photos/200?random=4",
      },
      ...myLocation,
    ]);
    setActiveLocation({
      name: place?.name,
      position: { lat: lat(), lng: lng() },
      img: place.photos?.[0].getUrl() || "https://picsum.photos/200?random=4",
    });
  };

  const toggleSearch = () => {
    setToggle(!isShowSearch);
  };

  function toggleMyLocation() {
    setToggleLocation(!isShowMyLocation);
  }

  function onSelectMyLocation(selectedLocation) {
    setToggleLocation(!isShowMyLocation);
    setActiveLocation(selectedLocation);
    const position = selectedLocation.position;
    const { lat, lng } = position;
    dispatch(setCoordinates({ lat, lng }));
    map.setCenter(position);
  }

  function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
      <div className="mt-2">
        <SliderThumb {...other}>
          {children}
          <span className="text-[#fff] text-[12px]">{value}km</span>
        </SliderThumb>
      </div>
    );
  }

  function getCircleBounds(centerLat, centerLng, radiusInKm) {
    if (!window.google) {
      onRadiusValue(null);
      return;
    }
    const EARTH_RADIUS = 6371; // Earth's radius in kilometers

    // Helper function to convert degrees to radians
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    // Helper function to convert radians to degrees
    function toDegrees(radians) {
      return radians * (180 / Math.PI);
    }

    // Latitude boundaries
    const latRadius = radiusInKm / EARTH_RADIUS;
    const minLat = centerLat - toDegrees(latRadius);
    const maxLat = centerLat + toDegrees(latRadius);

    // Longitude boundaries
    const lngRadius =
      radiusInKm / (EARTH_RADIUS * Math.cos(toRadians(centerLat)));
    const minLng = centerLng - toDegrees(lngRadius);
    const maxLng = centerLng + toDegrees(lngRadius);

    // Create bounds object (using Google Maps LatLngBounds)
    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng), // Southwest corner
      new window.google.maps.LatLng(maxLat, maxLng) // Northeast corner
    );
    map.fitBounds(bounds);
    onRadiusValue(bounds);
  }

  return (
    <div className="w-60 absolute top-[10%] z-10 left-[20%]">
      <div className="relative flex flex-row items-center w-full">
        <div className="h-[40px]">
          <div
            onClick={toggleMyLocation}
            className={
              "max-w-10 max-h-10 w-10 h-10 mb-2 cursor-pointer z-20 shadow-lg rounded-full"
            }
          >
            <IconCircular
              className={"max-w-10 max-h-10 w-10 h-10"}
              alt={"language"}
              key={"language"}
              src={activeLocation?.img}
            />
          </div>
          {isShowMyLocation &&
            myLocation?.length > 0 &&
            myLocation.map((item, index) => {
              if (item.name === activeLocation.name) return;
              return (
                <div
                  onClick={() => onSelectMyLocation(item)}
                  key={index}
                  className={
                    "min-w-10 w-10 h-10 mb-6 cursor-pointer z-20 shadow-lg"
                  }
                >
                  <IconCircular
                    alt={"language"}
                    key={"language"}
                    src={item.img}
                  />
                  <div className="text-[8px] mt-1 font-bold text-center ">
                    {item.name}
                  </div>
                </div>
              );
            })}
        </div>
        <StyledSlider
          value={value}
          onChange={handleChange}
          onChangeCommitted={onSliderChangeCommited}
          min={0}
          max={50}
          step={0.1}
          valueLabelDisplay="off" // Auto display the value inside the thumb
          slots={{
            thumb: AirbnbThumbComponent, // Custom value label component
          }}
        />
        <button
          className="flex flex-col items-center justify-center ml-4 p-4 cursor-pointer w-7 h-7 border-none rounded-full bg-gradient-to-r from-[#4a90e2] to-primary-light shadow-lg transform transition-transform duration-300 ease-in-out hover:scale-110 hover:shadow-xl focus:outline-none"
          onClick={toggleSearch}
        >
          <SearchIcon
            className="text-secondary-l0"
            style={{ fontSize: "20px" }}
          />
        </button>
      </div>
      {isShowSearch && (
        <div className="flex flex-col flex-1 mt-5">
          <LocationAutocomplete
            onPlaceSelect={onPlaceSelect}
            handleSearchChange={handleSearchChange}
          />
        </div>
      )}
    </div>
  );
}
