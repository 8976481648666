import {
  getUserFavouriteListUrl,
  addFavouriteUrl,
  deleteUserFavouriteItemUrl,
} from "@/utils/apis/user";
import httpClient from "@/utils/apis/httpClient";
import { setUserStore, setRequestStatus } from "@/store/User/userReducers";

export const getFavouriteList = (id) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.get(`${getUserFavouriteListUrl}/${id}`);
    dispatch(setUserStore({ favouriteList: response?.data?.favouriteList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};
export const addToFavouriteList = (params) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.post(addFavouriteUrl, { ...params });
    dispatch(setUserStore({ favouriteList: response?.data?.favouriteList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};
export const deleteFavouriteItem = (userId, listingId) => async (dispatch) => {
  dispatch(setRequestStatus({ isLoading: true }));
  try {
    let response = await httpClient.delete(
      `${deleteUserFavouriteItemUrl}/${userId}/${listingId}`
    );
    dispatch(setUserStore({ favouriteList: response?.data?.favouriteList }));
    dispatch(setRequestStatus({ isLoading: false }));
  } catch (error) {
    dispatch(setRequestStatus({ isLoading: false }));
  }
};
