import React, { useState } from "react";
import Logo from "../Logo";
import IconCircular from "../IconCircular/IconCircular";
import { FiMoreVertical } from "react-icons/fi";
import { Popover, Button, Divider } from "@mui/material";
import { supabase } from "../../utils/helpers/supabaseClient";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      // Handle logout success if needed
      localStorage.removeItem("user");
    }
    handleClose(); // Close the popover after logout
  };

  function handleChangePass() {
    navigate("/reset-password");
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <header className="flex flex-col bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE] h-20 max-h-20 text-white px-4">
      <div className="flex flex-row flex-1 w-full justify-between items-center ">
        <Link
          to={"/home"}
          className="flex flex-row items-center gap-3 cursor-pointer no-underline"
        >
          <Logo classes={"w-12 h-12 cur"} />
          <span className="text-secondary-l0 font-semibold !no-underline">
            LifeMatch
          </span>
        </Link>
        <div className="flex flex-row gap-3">
          {/* Search and other components */}
        </div>
        <div className="flex flex-row">
          <div className="flex flex-row items-center gap-3">
            <IconCircular
              alt={"language"}
              className={"w-10 h-10 cursor-pointer"}
              key={"language"}
              src={"https://picsum.photos/200"}
            />
            <FiMoreVertical
              className="text-secondary-l0 cursor-pointer"
              size={40}
              onClick={handleMoreClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div className="flex flex-col  p-2">
                <div
                  onClick={handleChangePass}
                  className="text-xs cursor-pointer mb-2"
                >
                  Change password
                </div>
                <Divider orientation="vertical" flexItem />
                <div
                  onClick={handleLogout}
                  className="text-xs cursor-pointer mb-2"
                >
                  Logout
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
