import React, { useEffect, useState } from "react";
import { Popover, Button, Checkbox, FormControlLabel } from "@mui/material";
import ConstructionIcon from "@mui/icons-material/Construction";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import drawIcon from "../../../assets/icons/svg/draw-icon.svg";

const Tools = ({ onDrawChange, onRadiusChange, clear }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isRadiusChecked, setIsRadiusChecked] = useState(false);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    onDrawChange(event.target.checked);
    setAnchorEl(null);
  };

  const handleRadiusCheckboxChange = (event) => {
    setIsRadiusChecked(event.target.checked);
    onRadiusChange(event.target.checked);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "tools-popover" : undefined;

  useEffect(() => {
    if (!clear) setIsChecked(false);
  }, [clear]);

  return (
    <div>
      {/* Tools Button */}
      {!isChecked && !isRadiusChecked && (
        <div
          variant="contained"
          className="z-10 bottom-8 right-3 absolute bg-[#fff] rounded-lg drop-shadow-2xl p-2 text-center cursor-pointer"
          onClick={handleOpen}
        >
          <ConstructionIcon
            className="text-white"
            sx={{ color: "#4B8FE2" }}
            style={{ fontSize: "28px" }}
          />
        </div>
      )}
      {isChecked && (
        <div
          variant="contained"
          className="z-10 bottom-8 right-3 absolute bg-[#fff] rounded-lg drop-shadow-2xl p-2 text-center cursor-pointer"
          onClick={handleOpen}
        >
          <img alt="draw" src={drawIcon} />
          <div className="text-xs font-normal">Draw</div>
        </div>
      )}
      {isRadiusChecked && (
        <div
          variant="contained"
          className="z-10 bottom-8 right-3 absolute bg-[#fff] rounded-lg drop-shadow-2xl p-2 text-center cursor-pointer"
          onClick={handleOpen}
        >
          <ConstructionIcon
            className="text-white"
            sx={{ color: "#4B8FE2" }}
            style={{ fontSize: "28px" }}
          />
          <div className="text-xs font-normal">Radius</div>
        </div>
      )}

      {/* Popover for Tools */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {/* Fancy Checkboxes */}
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                sx={{
                  color: "#4A90E2",
                  "&.Mui-checked": {
                    color: "#4A90E2",
                  },
                }}
              />
            }
            label="Draw"
            sx={{
              ".MuiFormControlLabel-label": {
                fontSize: "1rem",
                fontWeight: "bold",
                color: "#333",
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isRadiusChecked}
                onChange={handleRadiusCheckboxChange}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
                sx={{
                  color: "#4A90E2",
                  "&.Mui-checked": {
                    color: "#4A90E2",
                  },
                }}
              />
            }
            label="Radius"
            sx={{
              ".MuiFormControlLabel-label": {
                fontSize: "1rem",
                fontWeight: "bold",
                color: "#333",
              },
            }}
          />
        </div>
      </Popover>
    </div>
  );
};

export default Tools;
