import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotels: [],
  loadingStore: null,
  listingDetail: null,
  nearbyPlace: null,
};

export const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    setHotels: (state, action) => {
      state.hotels = action?.payload;
    },
    setListingDetail: (state, action) => {
      state.listingDetail = action?.payload;
    },
    setNearbyPlace: (state, action) => {
      state.nearbyPlace = action?.payload;
    },
    setRequestStatus: (state, action) => {
      state.loadingStore = { ...state.loadingStore, ...action.payload };
    },
  },
});
export const { setHotels, setRequestStatus, setListingDetail, setNearbyPlace } =
  hotelSlice.actions;
export default hotelSlice.reducer;
