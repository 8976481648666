import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box } from "@mui/material";
import InfoCard from "./InfoCard";
import InfoBuyRentCard from "./InfoBuyRentCard";
import ChipContainer from "./ChipContainer";
import ImageGallery from "./ImageGallery";
import { useDispatch, useSelector } from "react-redux";
import { setActiveStepAction } from "../../store/Map/mapActions";
import BottomMenu from "../BottomMenu/BottomMenu";
import {
  getListingDetailAction,
  getlistingNearbyPlaceAction,
} from "../../store/Listing/listingAction";

const Swipe = ({ balooDir, isMobileScreen = false }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const activeStep = useSelector((state) => state.map.activeStep);
  const dispatch = useDispatch();
  const listingDetail = useSelector((state) => state.hotels.listingDetail);
  const nearbyPlace = useSelector((state) => state.hotels.nearbyPlace);
  const hotels = useSelector((state) => state.hotels);
  const [swipeList, setList] = useState(hotels?.hotels?.features || []);
  const [activeItem, setActive] = useState(null);

  useEffect(() => {
    setList(hotels?.hotels?.features || []);
  }, [hotels]);

  useEffect(() => {
    const item = swipeList.find((item) => item.id === activeStep);
    setActive(item);
  }, [activeStep]);

  useEffect(() => {
    console.log("active itemssssss", activeItem);
    if (activeItem) {
      dispatch(
        getListingDetailAction(
          {
            checkin_date: activeItem?.properties?.checkinDate,
            hotel_id: activeItem?.properties?.id,
            checkout_date: activeItem?.properties?.checkoutDate,
          },
          activeItem?.properties?.accommodationType
        )
      );
      dispatch(
        getlistingNearbyPlaceAction({
          id: activeItem?.properties?.id,
        })
      );
    }
  }, [activeItem]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSwipe = (index) => {
    setActiveStepAction(index);
  };

  useEffect(() => {
    console.log(balooDir);
  }, [balooDir]);

  if (!swipeList || swipeList?.length <= 0) {
    return (
      <div className="flex justify-center items-center h-full bg-gradient-to-b from-[#1B3FFC] to-[#1BD8FE]">
        <div>Please search for a place to load property</div>
      </div>
    );
  }
  const filterStyle = {
    rent: { bg: "#94DC20" },
    Hotel: { bg: "#5397FD" },
    Shortterm: { bg: "#FF5139" },
    buy: { bg: "#DC8520" },
  };
  console.log("nearbyPlace>>>>", nearbyPlace);
  return (
    <div className="flex flex-col h-full">
      {/* Header: Tabs for Hotel Details, My Locations, The Area */}
      <div className="sm:h-[27%] h-[29%]">
        <Box
          sx={{ boxShadow: 3, mb: 1 }}
          className="bg-gradient-to-b from-[#1B3FFC]/30 to-[#1BD8FE]/30 "
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab
              className="!text-[16px] !normal-case !font-normal"
              label="Hotel Details"
            />
            <Tab
              className="!text-[16px] !normal-case !font-normal"
              label="My Locations"
            />
            <Tab
              className="!text-[16px] !normal-case !font-normal"
              label="The Area"
            />
          </Tabs>
        </Box>

        {/* Main Content */}
        <div className="flex-1 overflow-hidden flex flex-col">
          <div className="w-full">
            {activeItem?.properties?.accommodationType === "rent" ||
            activeItem?.properties?.accommodationType === "buy" ? (
              <InfoBuyRentCard details={activeItem || swipeList?.[0]} />
            ) : (
              <InfoCard
                details={activeItem || swipeList?.[0]}
                listingDetail={listingDetail}
              />
            )}
          </div>
        </div>
      </div>
      <div className="relative h-[63.2%]">
        {/* Main Image Gallery (taking the remaining height) */}
        {(activeItem || swipeList?.[0]) && (
          <div className=" h-full">
            <ImageGallery
              swipeList={swipeList}
              balooDir={balooDir}
              details={activeItem || swipeList?.[0]}
              isMobileScreen={isMobileScreen}
              listingDetail={listingDetail}
              nearbyPlace={nearbyPlace}
            />
          </div>
        )}

        {/* Chips Section */}
        {(activeItem || swipeList?.[0]) && (
          <ChipContainer
            details={activeItem || swipeList?.[0]}
            listingDetail={listingDetail}
          />
        )}
      </div>

      {/* Footer: Bottom Menu */}
      <Box sx={{ boxShadow: 1 }} className="sm:h-[1%] h-[5%]">
        <BottomMenu
          isMobileScreen={isMobileScreen}
          activeItem={activeItem}
          filterStyle={filterStyle}
        />
      </Box>
    </div>
  );
};

export default Swipe;
